export const $themeColors = {}

export const $themeBreakpoints = {}

export const $themeConfig = {
  app: {
    appName: '', // Will update name in navigation menu (Branding)
    // eslint-disable-next-line global-require
    appLogoImage: require('@/assets/images/logo/logo-menu-small.svg'), // Will update logo in navigation menu (Branding)
    appNameImage: require('@/assets/images/logo/logo-primary.svg'),
    appManufacturerImage: require('@/assets/images/logo/developedby-secondary.svg'),
    appManufacturerIcon: require('@/assets/images/logo/cc-icon.svg')
  },
  layout: {
    isRTL: false,
    skin: 'light',
    routerTransition: 'zoom-fade',
    type: 'vertical',
    contentWidth: 'full',
    menu: {
      hidden: false,
      isCollapsed: false,
    },
    navbar: {
      type: 'sticky',
      backgroundColor: '',
    },
    footer: {
      type: 'sticky',
    },
    customizer: false,
    enableScrollToTop: false,
  },
}
