import Vue from 'vue'
import http from '@axios'
import { tr } from 'date-fns/locale'

const intercepResponse = response => response

const intercepResponseError = async error => {
  if (error.message === 'Network Error') {
    let img = require('@/assets/images/disconected_icon.svg')
    await Vue.prototype.$swal({
      title: 'Não foi possível estabelecer conexão',
      text: 'Verifique se você está conectado com a internet e tente novamente em seguida.',
      iconHtml: `<img src=${img} width=125 height=39>`,
      customClass: {
        icon: 'no-border'
      },
    })
  }

  const errorObject = {
    500: {
      title: 'Algo deu errado!',
      message: 'A ação não pode ser executada. Tente novamente ou entre em contato com um de nossos administradores.',
    },
    502: {
      title: 'Algo deu errado!',
      message: 'A ação não pode ser executada. Tente novamente ou entre em contato com um de nossos administradores.',
    },
    503: {
      title: 'Algo deu errado!',
      message: 'A ação não pode ser executada. Tente novamente ou entre em contato com um de nossos administradores.',
    },
    504: {
      title: 'Não foi possível estabelecer conexão',
      message: 'Verifique se você está conectado com a internet e tente novamente em seguida.',
    },
    400: {
      title: 'Algo deu errado!',
      message: 'A ação não pode ser executada. Tente novamente ou entre em contato com um de nossos administradores.',
    },
    403: {
      title: 'Acesso negado',
      message: 'Você não tem permissão para acessar essa página ou recurso da plataforma.',
    },
    404: {
      title: 'Algo deu errado!',
      message: 'A ação não pode ser executada. Tente novamente ou entre em contato com um de nossos administradores.',
    },
    422: {
      title: 'Erro no formulário',
      message: 'Erro no servidor, entre em contato com a adminstração',
    }
  }

  if ([422, 503].includes(error.response.status)) {
    let errorString = ''
    try {
      errorString = error.response.data.errors.map(obj => `<li>${obj}</li>`).join('')
    } catch (e) {
      errorString = error.response.data.map(obj => `<li>${obj}</li>`).join('')
    }

    errorObject[error.response.status].message = `<ul class="text-left" style="list-style-type:none">${errorString}</ul>`
  }

  if (error.config.url.includes('/representante_legal_avalista') && error.response.status === 422) return Promise.reject(error)

  if (error.config.url.includes('/login') && error.response.status === 422) return Promise.reject(error)

  if (errorObject[error.response.status]) {
    await Vue.prototype.$swal.fire({
      title: errorObject[error.response.status].title,
      html: errorObject[error.response.status].message,
      icon: 'error',
    })
  }

  return Promise.reject(error)
}

http.interceptors.response.use(intercepResponse, intercepResponseError)

export default http
