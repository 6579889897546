import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import company from './company'
import partner from './partner'
import admin from './admin'

Vue.use(VueRouter)

const homeRouterByUserType = () => {
  const userData = getUserData()
  return getHomeRouteForLoggedInUser(userData?.user_type)
}

const dashboardMiddleware = (to, next, state) => {
  const isDashboardRoute = to.path.includes('dashboard')
  if (!isDashboardRoute || ['admin', 'parceiro'].includes(state.auth.userData.user_type)) return
 
  const currentCompany = state.company.current
  const analysisPendingOrInProgress = ['pendente', 'em_progresso'].includes(currentCompany.status_analise)
  const isAaaCompany = currentCompany.classificacao_interna === 'aaa' || currentCompany.classificacao_interna === 'AAA'
  const scrInfosNotSended = currentCompany.status_scr_consent !== 'concluido'
  const analysisCompletedLimitInProgress = (currentCompany.status_analise === 'finalizada' && currentCompany.status_limite === 'pendente')
  const pendingAnalysis = analysisPendingOrInProgress || analysisCompletedLimitInProgress

  if (state.auth.userData.empresa?.status_analise === 'nao_solicitado') {
    if (to.path !== '/dashboard') next('/dashboard');
  }
  else if (
    (pendingAnalysis && !scrInfosNotSended) || !state.company.current.limite_aprovado
  ){
    if (to.path !== '/dashboard/status') next('/dashboard/status');
  }
  else if (
    ((currentCompany.status_analise === 'finalizada' 
    && !scrInfosNotSended)
    || isAaaCompany)
    && currentCompany.status_limite === 'definido' 
    && currentCompany.limite_aprovado
  ) {
    if (to.path !== '/dashboard/credito') next('/dashboard/credito');
  }
  else{
    if (to.path !== '/dashboard') next('/dashboard');
  }
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: homeRouterByUserType() },
    ...admin,
    ...company,
    ...partner,
    {
      path: '/login',
      name: 'auth-login',
      component: () => import('@/views/common/auth/pages/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/cadastrar',
      name: 'cadastrar',
      component: () => import('@/views/common/auth/pages/Register.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/auth/change-password/:token',
      name: 'change-password',
      component: () => import('@/views/common/auth/pages/ChangePassword.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/nao-autorizado',
      name: 'misc-not-authorized',
      component: () => import('@/views/common/pages/NotAuthorized.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Auth',
      },
    },
    {
      path: '/minha-conta',
      name: 'minha.conta',
      component: () => import('@/views/common/pages/Profile.vue'),
      meta: {
        action: 'read',
        resource: 'Auth',
        pageTitle: 'Minha Conta',
      },
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (to.meta.requiresAuth && !isLoggedIn) return next({ name: 'auth-login' })

  if (!canNavigate(to)) {
    // Redirect to log in if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  const userData = getUserData()

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next(getHomeRouteForLoggedInUser(userData?.user_type))
  }
  dashboardMiddleware(to, next, store.state)

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
