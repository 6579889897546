import http from './http'

class CreditAnalysis {
  sendDocument(payload) {
    const multipartPayload = Object.keys(payload).reduce((formData, key) => {
      formData.append(key, payload[key])
      return formData
    }, new FormData())

    return http.post('/analise_credito/documentos', multipartPayload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }
  deleteDocument(payload) {
    return http.patch(`/analise_credito/documentos/${payload}/inactivate`)
  }
}

export default new CreditAnalysis()
