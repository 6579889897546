/* eslint-disable consistent-return */
import LinkapitalSolicitation from '@/services/credit-analysis/linkapital_solicitation'
import SpedFiscal from '@/services/sped_fiscal'
import ImportFile from '@/services/import_file'
import Company from '@/services/company'
import XmlNfe from '@/services/xml_nfe'
import Scr from '@/services/scr'

export default {
  getSpedReferenceYears({ commit, rootState }) {
    if (rootState.dashboard.spedFiscal.referenceYears.length) return

    return new Promise((resolve, reject) => {
      SpedFiscal.referenceYears()
        .then(response => {
          commit('SET_SPED_FISCAL_REFERENCE_YEARS', response.data.anos_referencia)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  async sendSpeds(context, payload) {
    const requests = payload.map(sped => {
      if (sped.id) return

      return new Promise((resolve, reject) => {
        SpedFiscal.createSped(sped)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    })

    return Promise.allSettled(requests)
      .then(responses => {
        const errors = responses.filter(response => response.status === 'rejected')
        if (errors.length) {
          return Promise.reject(errors)
        }
        return Promise.resolve(responses)
      })
  },

  async sendNFE(context, payload) {
    const promises = []

    if (!payload.nfeAReceber.id) {
      const nfeAReceber = new Promise((resolve, reject) => {
        XmlNfe.createNfe(payload.nfeAReceber)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
      promises.push(nfeAReceber)
    }

    if (!payload.nfeAPagar.id) {
      const nfeAPagar = new Promise((resolve, reject) => {
        XmlNfe.createNfe(payload.nfeAPagar)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
      promises.push(nfeAPagar)
    }

    return Promise.allSettled(promises)
      .then(responses => {
        const errors = responses.filter(response => response.status === 'rejected')
        if (errors.length) {
          return Promise.reject(errors)
        }
        return Promise.resolve(responses)
      })
  },

  getQuestions() {
    return new Promise((resolve, reject) => {
      Scr.getQuestions()
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  sendConsent({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      Scr.sendConsent(payload)
        .then(async response => {
          if (payload.offerKind === 'manual') await dispatch('createManualSolicitation')
          else await dispatch('createDigitalSolicitation')

          await commit('auth/SET_SCR_CONSENT_STATUS', null, { root: true })
          await commit('company/SET_SCR_CONSENT_STATUS', null, { root: true })
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  checkForSpeds() {
    return new Promise((resolve, reject) => {
      SpedFiscal.get()
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  checkForNfes() {
    return new Promise((resolve, reject) => {
      XmlNfe.get()
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  importClients(context, payload) {
    return new Promise((resolve, reject) => {
      ImportFile.clients(payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  createDigitalSolicitation({ commit, rootState }) {
    const { linkapitalSolicitation } = rootState.dashboard
    return new Promise((resolve, reject) => {
      LinkapitalSolicitation.digital(linkapitalSolicitation)
        .then(async response => {
          await commit('auth/SET_STATUS_ANALISE', null, { root: true })
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  createManualSolicitation({ commit }) {
    return new Promise((resolve, reject) => {
      LinkapitalSolicitation.manual()
        .then(async response => {
          await commit('auth/SET_LINKAPITAL_SOLICITATION', null, { root: true })
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  sendCommercialNote({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      Company.commercialNotes(payload)
        .then(async response => {
          const { data } = await dispatch('auth/getUserData', null, { root: true })
          response.data.limite_disponivel = data.limite_disponivel
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  simulate({ rootState }, payload) {
    const { id } = rootState.auth.userData.empresa
    return new Promise((resolve, reject) => {
      Company.simulate(id, payload)
        .then(async response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  save(context, payload) {
    return new Promise((resolve, reject) => {
      const { id } = payload
      const method = id ? 'update' : 'create'
      Company[method](payload)
        .then(async response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  updateA1Certificate({ commit }, certificate) {
    commit('UPDATE_LINKAPITALSOLICITATION_CERTIFICATE', certificate)
  },

  validateA1Certificate(context, payload) {
    return new Promise((resolve, reject) => {
      LinkapitalSolicitation.validate(payload)
        .then(async response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  getEmpresaLimits({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Company.getEmpresaLimits(payload.data_vencimento)
        .then(async response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}
