import http from "@/services/http";

class Concession {
    fetch() {
        return http.get('/concessao/empresa/operacoes')
    }

    getConcession(id) {
        return http.get(`/concessao/empresa/operacoes/${id}`)
    }

    getPartnerConcession(id) {
        return http.get(`/concessao/parceiro/operacoes/${id}`)
    }

    getConcessionByAdmin(id) {
        return http.get(`/concessao/admin/operacoes/${id}`)
    }

    getPreviewEncargos(id, data_pagamento) {
        return http.get(`/concessao/notas_comerciais/${id}/calcular_valor_devido_preview`, { params: { date: data_pagamento }})
    }
    postNcSetDue(id) {
        return http.post(`/concessao/notas_comerciais/nota_comercial_vencida`, { id: id })
    }


    checkForCanceledNotes() {
        return http.get('/concessao/empresa/operacoes/canceladas_recentemente')
    }

    checkForExpiredNotes() {
      return http.get('/concessao/empresa/operacoes/vencida')
    }

    checkForExecutedNotes() {
      return http.get('/concessao/empresa/operacoes/executada')
    }

    checkForToExpiringNotes() {
        return http.get('/concessao/empresa/operacoes/a_vencer')
    }

    generateCnabFile(ids) {
        return http.post('/concessao/notas_comerciais/cnab', { notas_comerciais_ids: ids }, { responseType: 'blob' })
    }

    deposit(notaComercialID) {
        return http.post('concessao/notas_comerciais/depositado', {id: notaComercialID})
    }

    depositClient(notaComercialID) {
        return http.post('concessao/notas_comerciais/executar_deposito_para_cliente', {id: notaComercialID})
    }

    updateStatus(notaComercialID, status) {
        return http.put(`/concessao/notas_comerciais/${notaComercialID}/update_status`, { status: status })
    }

    depositClient(notaComercialID) {
        return http.post('/concessao/notas_comerciais/executar_deposito_para_cliente', { id: notaComercialID })
    }

    createLiquidation(payload) {
        return http.post('/concessao/liquidacao', payload)
    }

    createDelayedLiquidation(id, data_liquidacao) {
        return http.post(`/concessao/notas_comerciais/${id}/calcular_valor_devido_e_liquida`, { date: data_liquidacao })
    }

    executeLiquidation(liquidacaoID) {
        return http.patch('/concessao/liquidacao/execute', { liquidacao_id: liquidacaoID })
    }
}

export default new Concession
