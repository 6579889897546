import http from './http';

class Scr {
  getQuestions() {
    return http.get('/analise_credito/log_consentimento_scrs/lista_pergunta');
  }

  sendConsent(payload) {
    return http.post('/analise_credito/log_consentimento_scrs/consentimento_usuario', {log_consentimento_scr: payload});
  }
}

export default new Scr();