import http from './http';

class SpedFiscal {
  referenceYears() {
    return http.get('/analise_credito/sped_fiscal/anos_referencia');
  }

  createSped(payload) {
    let formData = new FormData();

    formData.append('file', payload.file);
    formData.append('ano_referencia', payload.ano_referencia);

    return http.post('/analise_credito/sped_fiscal', formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });
  }

  get() {
    return http.get('/analise_credito/sped_fiscal');
  }
}

export default new SpedFiscal();