import http from '@/services/http'

/**
 * Singleton class representing a company management module.
 * @class
 */
class Company {
  /**
   * Constructs a new instance of the Company module.
   * Private to enforce the Singleton pattern.
   * @private
   */
  constructor() {
    this.http = http
  }

  /**
   * Get the instance of the Company module.
   * @returns {Company} The Company instance.
   */
  static getInstance() {
    if (!Company.instance) {
      Company.instance = new Company()
    }
    return Company.instance
  }

  /**
   * Creates a new company.
   * @param {Object} payload - The payload data for creating the company.
   * @returns {Promise} A promise that resolves with the response data.
   */
  create(payload) {
    const newFormData = Object.keys(payload).reduce((formData, key) => {
      formData.append(`empresa[${key}]`, payload[key]);
      return formData;
    }, new FormData());

    return this.http.post('/backoffice/empresas/', newFormData, {
      headers: {"Content-Type": "multipart/form-data"}
    })
  }

  /**
   * Updates an existing company.
   * @param {Object} payload - The payload data for updating the company.
   * @returns {Promise} A promise that resolves with the response data.
   */
  update(payload) {
    const newFormData = Object.keys(payload).reduce((formData, key) => {
      formData.append(`empresa[${key}]`, payload[key]);
      return formData;
    }, new FormData());

    return this.http.put(`/backoffice/empresas/${payload.id}`, newFormData, {
      headers: {"Content-Type": "multipart/form-data"}
    })
  }

  /**
   * Complements the registration of a company.
   * @param {Object} payload - The payload data for complementing the registration.
   * @returns {Promise} A promise that resolves with the response data.
   */
  complementInfos(payload) {
    const newFormData = Object.keys(payload).reduce((formData, key) => {
      formData.append(`empresa[${key}]`, payload[key]);
      return formData;
    }, new FormData());

    return this.http.put('/backoffice/empresas/complementar_cadastro', newFormData, {
      headers: {"Content-Type": "multipart/form-data"}
    })
  }

  /**
   * Retrieves information about a specific company.
   * @param {string} id - The ID of the company to retrieve.
   * @returns {Promise} A promise that resolves with the response data.
   */
  getCompany(id) {
    return this.http.get(`/backoffice/empresas/${id}`)
  }

  /**
   * Creates an invitation for a contact associated with a company.
   * @param {string} id - The ID of the company.
   * @param {Object} payload - The payload data for creating the invitation.
   * @returns {Promise} A promise that resolves with the response data.
   */
  createInviteContact(id, payload) {
    return this.http.post(`/backoffice/empresas/${id}/create_convite_from_empresa`, { convite: payload })
  }

  /**
   * Sends documentation for analysis for a company.
   * @param {string} id - The ID of the company.
   * @returns {Promise} A promise that resolves with the response data.
   */
  enviarDocumentacaoParaAnalise(id) {
    return this.http.post(`/backoffice/empresas/${id}/enviar_documentacao_para_analise`)
  }

  /**
   * Uploads a social contract document for a company.
   * @param {string} id - The ID of the company.
   * @param {File} payload - The social contract file.
   * @returns {Promise} A promise that resolves with the response data.
   */
  uploadSocialContract(id, payload) {
    const formData = new FormData()
    formData.append('contrato_social', payload)

    return this.http.post(`/backoffice/empresas/${id}/upload_contrato_social`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }

  /**
   * Updates tax-related information for a company.
   * @param {Object} payload - The payload data for updating the tax information.
   * @returns {Promise} A promise that resolves with the response data.
   */
  updateTax(payload) {
    const parsedPayload = {
      taxa_juros: payload.taxa_juros,
      taxa_escrituracao: payload.taxa_escrituracao,
      mora_atraso: payload.mora_atraso,
      multa_atraso: payload.multa_atraso,
      prazo: payload.prazo,
      limite: payload.limite,
      limite_aprovado: payload.limite_aprovado,
      limite_por_dia: payload.limite_por_dia,
    }
    return this.http.put(`/backoffice/empresas/${payload.id}/update_taxa_prazo_limite`, parsedPayload)
  }

  /**
   * Approves a set of documents.
   * @param {Object} payload - The payload data for approving the documents.
   * @returns {Promise} A promise that resolves with the response data.
   */
  approveDocuments(payload) {
    return this.http.post('/backoffice/validacao_documentacao/aprovado', payload)
  }

  /**
   * Rejects a set of documents.
   * @param {Object} payload - The payload data for rejecting the documents.
   * @returns {Promise} A promise that resolves with the response data.
   */
  repproveDocuments(payload) {
    return this.http.post('/backoffice/validacao_documentacao/reprovado', payload)
  }

  /**
   * Retrieves the invitation details for a company.
   * @param {string} id - The ID of the company.
   * @returns {Promise} A promise that resolves with the response data.
   */
  getInvites(id) {
    return this.http.get(`/backoffice/empresas/${id}/convites`)
  }

  /**
   * Creates commercial notes.
   * @param {Object} payload - The payload data for creating the commercial notes.
   * @returns {Promise} A promise that resolves with the response data.
   */
  commercialNotes(payload) {
    return this.http.post('/concessao/notas_comerciais', payload)
  }

  /**
   * Simulates a loan for a company.
   * @param {string} id - The ID of the company.
   * @param {Object} payload - The payload data for the loan simulation.
   * @returns {Promise} A promise that resolves with the response data.
   */
  simulate(id, payload) {
    return this.http.get(`/concessao/simulacao/${id}/valores_emprestimo`, { params: payload })
  }

  /**
   * Retrieves analysis results for a company.
   * @param {string} id - The ID of the company.
   * @returns {Promise} A promise that resolves with the response data.
   */
  analysisResult(id) {
    return this.http.get(`/backoffice/empresas/${id}/analises`)
  }

  /**
   * Update the apto_para_operar flag for a company.
   * @param {String} id - The ID of the company.
   * @param {Object} payload - { apto_para_operar: true|false } - The payload data for updating the flag value
   * @returns {Promise}
   */
  updateCanOperate(id, payload) {
    return this.http.put(`/backoffice/empresas/${id}/update_apto_para_operar`, payload)
  }

  getEmpresaLimits(data_vencimento) {
    return this.http.get(`/concessao/empresa/operacoes/limite`, { params: { data_vencimento: data_vencimento }})
  }
}

export default Company.getInstance()
