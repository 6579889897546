export default {
  SET_USER_DATA(state, payload) {
    state.userData = payload
  },

  UPDATE_USER_DATA(state, payload) {
    Object.keys(payload).forEach(key => {
      state.userData[key] = payload[key]
    })
  },

  SET_NEW_LIMIT(state, payload) {
    state.userData.empresa.limite = payload
  },

  SET_LINKAPITAL_SOLICITATION(state) {
    state.userData.empresa.status_analise = 'pendente'
  },

  SET_STATUS_ANALISE(state) {
    state.userData.empresa.status_analise = 'finalizada'
  },

  SET_STATUS_DOCUMENTATION(state) {
    state.userData.empresa.status_documentacao = 'em_validacao'
  },

  SET_SCR_CONSENT_STATUS(state) {
    state.userData.empresa.status_scr_consent = 'concluido'
  },
}
