import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

Vue.use(VueSweetalert2, {
  reverseButtons: true,
  confirmButtonColor: '#093272',
  customClass: {
    cancelButton: 'btn btn-outline-primary',
  },
})
