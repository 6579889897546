import http from "@/services/http";

class Representative {

    constructor(userType = 'empresa') {
        this.userType = userType
    }

    create(payload) {
        const newFormData = Object.keys(payload).reduce((formData, key) => {
            formData.append(`representante_legal[${key}]`, payload[key]);
            return formData;
        }, new FormData());

        return http.post('/backoffice/${this.userType}/representantes_legais', newFormData, {
            headers: {"Content-Type": "multipart/form-data"}
        })
    }

    save(payload) {
        const newFormData = new FormData()

        Object.keys(payload).filter(key => newFormData.append(`representante_legal[${key}]`, payload[key]));

        return http.post(`/backoffice/${this.userType === 'empresa' ? 'empresas' : 'admin'}/representantes_legais`, newFormData, {
            headers: {"Content-Type": "multipart/form-data"}
        })
    }

    update(payload) {
        const newFormData = new FormData()
        let payload_tmp = { ...payload }

        if(payload_tmp.comprovante_residencia.url !== undefined) delete payload_tmp.comprovante_residencia
        if(payload_tmp.documento_identidade.url !== undefined) delete payload_tmp.documento_identidade
        if(payload_tmp.procuracao.url !== undefined) delete payload_tmp.procuracao

        Object.keys(payload_tmp).filter(key => newFormData.append(`representante_legal[${key}]`, payload_tmp[key]));

        return http.patch(`/backoffice/${this.userType === 'empresa' ? 'empresas' : 'admin'}/representantes_legais/${payload_tmp.id}`, newFormData, {
            headers: {"Content-Type": "multipart/form-data"}
        })
    }

    destroy(id) {
        return http.delete(`/backoffice/${this.userType === 'empresa' ? 'empresas' : 'admin'}/representantes_legais/${id}`)
    }

    hasGuarantor(id) {
        return http.get(`/backoffice/empresas/${id}/representante_legal_avalista`)
    }

    hasValidSubscribers(id) {
        return http.get(`/backoffice/empresas/${id}/validar_representantes_legais`)
    }
}

export default Representative
