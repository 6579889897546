import {
  dateFormatter,
  moneyFormatDatatable,
  percentageFormatter,
  prazoFormatter,
  comercialNoteStatusBadge,
  liquidationStatusBadge,
} from '@core/comp-functions/data_visualization/datatable'

export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/client/dashboard/pages/CreditAnalysis.vue'),
    meta: {
      action: 'company-read',
      resource: 'company-user',
      pageTitle: 'Análise de Crédito',
      skipGoToHome: true,
    },
  },
  {
    path: '/dashboard/oferta-manual',
    name: 'dashboard.manual-offer',
    component: () => import('@/views/client/dashboard/pages/ManualOffer.vue'),
    meta: {
      action: 'company-read',
      resource: 'company-user',
    },
  },
  {
    path: '/analise-de-credito',
    name: 'credit-analysis',
    component: () => import('@/views/client/dashboard/pages/CreditAnalysis.vue'),
    meta: {
      action: 'company-read',
      resource: 'company-user',
      pageTitle: 'Análise de Crédito',
      skipGoToHome: true,
    },
  },
  {
    path: '/oferta-manual/scr/termos-linkapital',
    name: 'manual-offer.scr-linkapital-terms',
    component: () => import('@/views/client/dashboard/pages/LinkapitalAuthorizationTerms.vue'),
    meta: {
      action: 'company-read',
      resource: 'company-user',
    },
  },
  {
    path: '/oferta-manual/scr/termos-creditcorp',
    name: 'manual-offer.scr-creditcorp-terms',
    component: () => import('@/views/client/dashboard/pages/CreditcorpAuthorizationTerms.vue'),
    meta: {
      action: 'company-read',
      resource: 'company-user',
    },
  },
  {
    path: '/dashboard/status',
    name: 'dashboard.status',
    component: () => import('@/views/client/dashboard/pages/OfferStatus.vue'),
    meta: {
      action: 'company-read',
      resource: 'company-user',
      pageTitle: 'Análise de Crédito',
      skipGoToHome: true,
    },
  },
  {
    path: '/dashboard/oferta-digital',
    name: 'dashboard.digital-offer',
    component: () => import('@/views/client/dashboard/pages/DigitalOffer.vue'),
    meta: {
      action: 'company-read',
      resource: 'company-user',
    },
  },
  {
    path: '/dashboard/oferta-digital/scr-autorizacao',
    name: 'dashboard.digital-offer.scr-autorization',
    component: () => import('@/views/client/dashboard/pages/DigitalOfferScr.vue'),
    meta: {
      action: 'company-read',
      resource: 'company-user',
    },
  },
  {
    path: '/dashboard/credito',
    name: 'dashboard.credit',
    component: () => import('@/views/client/dashboard/pages/Credit.vue'),
    meta: {
      action: 'company-read',
      resource: 'company-user',
    },
  },
  {
    path: '/resgates',
    name: 'resgates',
    component: () => import('@/views/client/resgates/pages/Index.vue'),
    meta: {
      action: 'company-read',
      resource: 'company-user',
    },
  },
  {
    path: '/termo-condicoes-gerais',
    name: 'general-conditions-term',
    component: () => import('@/views/client/dashboard/pages/GeneralConditionsTerm.vue'),
    meta: {
      action: 'company-read',
      resource: 'company-user',
    },
  },
  {
    path: '/completar-cadastro',
    name: 'complete-registration',
    component: () => import('@/views/client/user/pages/CompleteRegistration.vue'),
    meta: {
      action: 'company-read',
      resource: 'company-user',
    },
  },
  {
    path: '/concessoes',
    name: 'concessoes.index',
    component: () => import('@/views/common/crud/pages/Index.vue'),
    meta: {
      action: 'company-read',
      resource: 'company-user',
      pageTitle: 'Concessões',
      actions: [
        {
          title: 'Nova concessão',
          variant: 'primary',
          routeName: 'dashboard',
        },
      ],
      indexObject: {
        serviceUrl: '/concessao/empresa/operacoes',
        editRouteName: 'concessoes.show',
        datatableTitle: 'Acompanhe abaixo todas as suas concessões',
        fields: [
          {
            label: 'Número',
            key: 'numero',
            sortable: true,
          },
          {
            label: 'Valor contratado',
            key: 'valor_emissao',
            sortable: true,
            formatter: moneyFormatDatatable,
          },
          {
            label: 'Valor a pagar',
            key: 'valor_devido',
            sortable: true,
            formatter: moneyFormatDatatable,
          },
          {
            label: 'Taxa de juros',
            key: 'taxa_juros',
            sortable: true,
            formatter: percentageFormatter,
          },
          {
            label: 'Taxa de Escrituração',
            key: 'taxa_escrituracao',
            sortable: true,
            formatter: percentageFormatter,
          },
          {
            label: 'Resgate',
            key: 'data_emissao',
            sortable: true,
            formatter: dateFormatter,
          },
          {
            label: 'Vencimento',
            key: 'data_vencimento',
            sortable: true,
            formatter: dateFormatter,
          },
          {
            label: 'Prazo',
            key: 'prazo',
            sortable: false,
            formatter: prazoFormatter,
          },
          {
            label: 'Nota Comercial',
            key: 'status',
            sortable: false,
            formatter: comercialNoteStatusBadge,
          },
          {
            label: 'Quitação',
            key: 'quitacao',
            sortable: false,
            formatter: liquidationStatusBadge,
          },
          { label: '', key: 'payment' },
        ],
        actions: [
          {
            label: 'Visualizar',
            icon: 'icon-search',
            action: 'show',
            name: 'concessoes.show',
          },
        ],
      },
    },
  },
  {
    path: '/concessoes/:id',
    name: 'concessoes.show',
    component: () => import('@/views/client/concessoes/pages/Show.vue'),
    meta: {
      action: 'company-read',
      resource: 'company-user',
    },
  },
  {
    path: '/concessoes/:id/liquidacao',
    name: 'concessoes.liquidacao',
    component: () => import('@/views/client/concessoes/pages/Liquidation.vue'),
    meta: {
      action: 'company-read',
      resource: 'company-user',
    },
  },
  {
    path: '/faq/scr',
    name: 'scr-help',
    component: () => import('@/views/client/dashboard/pages/ScrHelp.vue'),
    meta: {
      action: 'company-read',
      resource: 'company-user',
    },
  },
]
