import { testExpression, validCnpjExpression } from '../misc/regexp'

export const moneyFormat = (value, minimumFractionDigits = 2) => {
  if (!value) return 'R$ 0'

  return parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits })
}

export const moneyFormatDatatable = (value, key, row, minimumFractionDigits = 2) => {
  if (!value) return 'R$ 0,00'

  return parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits })
}

export const badge = value => {
  if (!value) return ''

  return `<span class="badge badge-primary badge-pill">
  ${value}</span>`
}

export const emptyFormatter = value => {
  if (!value) return '-'

  return value
}

export const cnpjFormatter = value => {
  if (!value) return '-'

  if (!testExpression(validCnpjExpression, value)) return value

  return value.replace(validCnpjExpression, '$1.$2.$3/$4-$5')
}

export const badgeClassification = value => {
  if (!value) return ''

  const variant = value === 'aaa' ? 'light-info' : 'light-warning'
  return `<span class="badge badge-${variant} badge-pill text-uppercase">
  ${value}</span>`
}

export const prazoFormatter = value => {
  if (!value) return ''

  return `${value} dias`
}

export const percentageFormatter = value => {
  if (!value) return ''

  return `${parseFloat(value).toFixed(2)}%`
}

export const dateFormatter = value => {
  if (!value) return ''

  return value.split('-').reverse().join('/')
}

export const stringToDateFormatter = value => {
  if (!value) return ''

  return new Date(`${value.split('/').reverse().join('-')}T00:00:00-0300`)
}

export const statusBadgeVariant = value => {
  if (!value) return ''

  let variant

  if (value === 'Processando') {
    variant = 'light-warning'
  } else if (value === 'Depositado') {
    variant = 'light-success'
  } else if (value === 'Cancelada') {
    variant = 'light-danger'
  } else {
    variant = 'light-info'
  }

  return variant
}

export const liquidationStatusBadge = value => {
  if (!value) return ''

  const variant = {
    Paga: 'success',
    Vencida: 'danger',
    'Aguardando pagamento': 'warning',
  }

  return `<span class="badge badge-light-${variant[value]} badge-text-dark border-${variant[value]} badge-pill">${value}</span>`
}

export const comercialNoteStatusBadge = value => {
  if (!value) return ''

  const variant = {
    aguardando_assinatura: 'warning',
    aguardando_transferencia: 'dark',
    executada: 'success',
    depositada: 'dark',
    liquidada: 'info',
    cancelada: 'danger',
    vencida: 'danger',
  }

  const status = {
    aguardando_assinatura: 'Aguardando assinatura',
    aguardando_transferencia: 'Aguardando depósito',
    executada: 'Depositada',
    depositada: 'Processando',
    liquidada: 'Liquidada',
    cancelada: 'Cancelada',
    vencida: 'Vencida',
  }

  return `<span class="badge badge-light-${variant[value]} badge-text-dark border-${variant[value]} badge-pill">${status[value]}</span>`
}

export const comercialNoteStatusBadgeForAdmin = value => {
  if (!value) return ''

  const variant = {
    aguardando_assinatura: 'warning',
    aguardando_transferencia: 'dark',
    executada: 'success',
    depositada: 'info',
    liquidada: 'info',
    cancelada: 'danger',
    vencida: 'danger',
  }

  const status = {
    aguardando_assinatura: 'Aguardando assinatura',
    aguardando_transferencia: 'Aguardando depósito',
    executada: 'Depositado > Cliente',
    depositada: 'Depositado > Roadcard',
    liquidada: 'Liquidada',
    cancelada: 'Cancelada',
    vencida: 'Vencida',
  }

  return `<span class="badge badge-light-${variant[value]} badge-text-dark border-${variant[value]} badge-pill">${status[value]}</span>`
}

export const userTypeName = (value) => {
  return {
    admin: 'Administrador',
    parceiro: 'Parceiro',
    cliente: 'Cliente',
    cedente: 'Cedente',
    empresa: 'Empresa',
    api: 'API',
  }[value] ?? value;
};
export const userStatusName = (value) => {
  return {
    active: 'Ativo',
    inactive: 'Inativo',
    blocked: 'Bloqueado',
  }[value] ?? value;
};
export const userStatusBadge = (value) => {
  if (!value) return '';
  const klass = {
    blocked: 'danger',
    inactive: 'warning',
    active: 'success',
  }[value] ?? 'dark';
  return `<span class="badge badge-light-${klass} border-${klass} badge-pill">${userStatusName(value)} </span>`;
};

export const documentationBadge = value => {
  if (!value) return ''

  const variant = {
    nao_enviado: 'warning',
    em_validacao: 'dark',
    aprovado: 'success',
    reprovado: 'danger',
  }

  const status = {
    nao_enviado: 'Não enviado',
    em_validacao: 'Em análise',
    aprovado: 'Aprovado',
    reprovado: 'Reprovado',
  }

  return `<span class="badge badge-light-${variant[value]} badge-text-dark border-${variant[value]} badge-pill">${status[value]}</span>`
}
