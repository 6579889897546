import http from '../http';

class LinkapitalSolicitation {
    manual(payload) {
        return http.post('/analise_credito/linkapital/solicitacao/manual', payload);
    }

    digital(payload) {
        let form = new FormData();
        form.append('certificado', payload.certificado);
        form.append('senha', payload.senha);

        return http.post('/analise_credito/linkapital/solicitacao/automatica', form, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
    }

    validate(payload) {
      let form = new FormData();
      form.append('certificado', payload.certificado);
      form.append('senha', payload.senha);

      return http.post('/analise_credito/validacao_a1', form, {
          headers: {
              "Content-Type": "multipart/form-data",
          }
      });
    }
}

export default new LinkapitalSolicitation();
