import http from "@/services/http";

class BankAccount {
    save(payload) {
        return http.post('/backoffice/contas_correntes_empresas', { conta_corrente: payload })
    }

    update(payload) {
        return http.put(`/backoffice/contas_correntes_empresas/${payload.id}`, { conta_corrente: payload })
    }

    destroy(id) {
        return http.delete(`backoffice/contas_correntes_empresas/${id}`)
    }
}

export default new BankAccount()
