import {
  moneyFormatDatatable,
  liquidationStatusBadge,
  percentageFormatter,
  dateFormatter,
  prazoFormatter,
  badgeClassification,
  documentationBadge,
  comercialNoteStatusBadgeForAdmin,
  userTypeName,
  userStatusBadge,
} from '@core/comp-functions/data_visualization/datatable'

export default [
  {
    path: '/admin/dashboard',
    name: 'admin-dashboard',
    component: () => import('@/views/admin/companies/pages/Index.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
      pageTitle: 'Dashboard',
      actions: [{
        title: 'Importar lista',
        variant: 'outline-primary',
        event: 'import-list',
        icon: 'UserIcon',
      }, {
        title: 'Criar novo cadastro',
        variant: 'primary',
        routeName: 'admin-dashboard.new',
        icon: 'PlusIcon',
      }],
      indexObject: {
        serviceUrl: 'backoffice/empresas',
        editRouteName: 'admin-dashboard.general-data',
        datatableTitle: 'Gerencie as empresas da plataforma',
        fields: [
          {
            label: 'CNPJ',
            key: 'cnpj',
            sortable: true,
          },
          {
            label: 'Nome fantasia',
            key: 'nome_fantasia',
            sortable: true,
          },
          {
            label: 'Razão social',
            key: 'razao_social',
            sortable: true,
          },
          {
            label: 'Limite aprovado',
            key: 'limite_aprovado',
            sortable: true,
            formatter: 'booleanFormatter',
          },
          {
            label: 'Documentação',
            key: 'status_documentacao',
            sortable: true,
            formatter: documentationBadge,
          },
          {
            label: 'Apto para operar',
            key: 'apto_para_operar',
            sortable: true,
            formatter: 'booleanFormatter',
          },
          {
            label: 'Classificação',
            key: 'classificacao_interna',
            sortable: true,
            formatter: badgeClassification,
          },
        ],
        actions: [
          {
            label: 'Editar',
            icon: 'icon-pencil',
            action: 'edit',
          },
          {
            label: 'Visualizar',
            icon: 'icon-pencil',
            action: 'show',
          },
          {
            label: 'Excluir',
            icon: 'icon-trash',
            action: 'delete',
          },
        ],
        filters: [{
          category: 'Por parâmetro',
          fields: [
            {
              label: 'CNPJ',
              key: 'cnpj',
              type: 'text',
            },
            {
              label: 'Nome Fantasia',
              key: 'nome_fantasia',
              type: 'text',
            },
            {
              label: 'Razão Social',
              key: 'razao_social',
              type: 'text',
            },
            {
              label: 'Limite Aprovado',
              key: 'limite_aprovado',
              type: 'select',
              options: [
                {
                  label: 'Sim',
                  value: 'true',
                },
                {
                  label: 'Não',
                  value: 'false',
                },
              ],
            },
            {
              label: 'Documentação',
              key: 'status_documentacao',
              type: 'select',
              options: [
                {
                  label: 'Não enviada',
                  value: 'nao_enviado',
                },
                {
                  label: 'Em análise',
                  value: 'em_validacao',
                },
                {
                  label: 'Aprovado',
                  value: 'aprovado',
                },
                {
                  label: 'Reprovado',
                  value: 'reprovado',
                },
              ],
            },
            {
              label: 'Classificação',
              key: 'classificacao_interna',
              type: 'select',
              options: [
                {
                  label: 'AAA',
                  value: 'aaa',
                },
                {
                  label: 'M',
                  value: 'm',
                },
              ],
            },
            {
              label: 'Apto Para Operar',
              key: 'apto_para_operar',
              type: 'select',
              options: [
                {
                  label: 'Sim',
                  value: 'true',
                },
                {
                  label: 'Não',
                  value: 'false',
                },
              ],
            },
          ],
        }],
      },
    },
  },
  {
    path: '/admin/dashboard/novo',
    component: () => import('@/layouts/vertical/LayoutVerticalCompany.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
    },
    children: [
      {
        path: '/',
        name: 'admin-dashboard.new',
        component: () => import('@/views/admin/dashboard/pages/New.vue'),
        meta: {
          action: 'admin-read',
          resource: 'admin-user',
        },
      },
    ],
  },
  {
    path: '/admin/dashboard/:id',
    component: () => import('@/layouts/vertical/LayoutVerticalCompany.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
    },
    children: [
      {
        path: 'dados-gerais',
        name: 'admin-dashboard.general-data',
        component: () => import('@/views/admin/dashboard/pages/GeneralData.vue'),
        meta: {
          action: 'admin-read',
          resource: 'admin-user',
          title: 'Empresa',
        },
      },
      {
        path: 'representantes',
        name: 'admin-dashboard.representatives',
        component: () => import('@/views/admin/dashboard/pages/Representative.vue'),
        meta: {
          action: 'admin-read',
          resource: 'admin-user',
          title: 'Representantes legais',
        },
      },
      {
        path: 'documentos',
        name: 'admin-dashboard.documents',
        component: () => import('@/views/admin/dashboard/pages/Documents.vue'),
        meta: {
          action: 'admin-read',
          resource: 'admin-user',
          title: 'Análise de documentos',
        },
      },
      // {
      //   path: 'contas-bancarias',
      //   name: 'admin-dashboard.bank-accounts',
      //   component: () => import('@/views/admin/dashboard/pages/BankAccount.vue'),
      //   meta: {
      //     action: 'admin-read',
      //     resource: 'admin-user',
      //   }
      // },
      {
        path: 'taxa',
        name: 'admin-dashboard.tax',
        component: () => import('@/views/admin/dashboard/pages/Tax.vue'),
        meta: {
          action: 'admin-read',
          resource: 'admin-user',
          title: 'Taxas e limites',
        },
      },
      {
        path: 'convites',
        name: 'admin-dashboard.invite',
        component: () => import('@/views/admin/dashboard/pages/Invite.vue'),
        meta: {
          action: 'admin-read',
          resource: 'admin-user',
          title: 'Convites',
        },
      },
    ],
  },
  {
    path: '/admin/resgates',
    name: 'admin-resgates',
    component: () => import('@/views/client/resgates/pages/Index.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
    },
  },
  {
    path: '/admin/concessoes',
    name: 'admin-concessions.index',
    component: () => import('@/views/admin/concessions/pages/Index.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
      pageTitle: 'Concessões de crédito',
      indexObject: {
        serviceUrl: '/concessao/admin/operacoes',
        fileExportUrl: '/concessao/admin/exportar_xlsx',
        editRouteName: 'admin-concessions.show',
        fields: [
          {
            label: '',
            key: 'select',
          },
          {
            label: 'Numero',
            key: 'numero',
            sortable: true
          },
          {
            label: 'CNPJ',
            key: 'empresa.cnpj',
            sortable: true,
            class: 'cnpj-column',
          },
          {
            label: 'Empresa',
            key: 'empresa.nome_fantasia',
            sortable: true,
          },
          {
            label: 'Valor contratado',
            key: 'valor_emissao',
            sortable: true,
            formatter: moneyFormatDatatable,
          },
          {
            label: 'Valor a pagar',
            key: 'valor_devido',
            sortable: true,
            formatter: moneyFormatDatatable,

          },
          {
            label: 'Taxa a.m.',
            key: 'taxa_juros',
            sortable: true,
            formatter: percentageFormatter,
          },
          {
            label: 'Taxa de Escrituração',
            key: 'taxa_escrituracao',
            sortable: true,
            formatter: percentageFormatter,
          },
          {
            label: 'Resgate',
            key: 'data_emissao',
            sortable: true,
            formatter: dateFormatter,
          },
          {
            label: 'Vencimento',
            key: 'data_vencimento',
            sortable: true,
            formatter: dateFormatter,
          },
          {
            label: 'Prazo',
            key: 'prazo',
            sortable: false,
            formatter: prazoFormatter,
          },
          {
            label: 'Recebimento',
            key: 'status',
            sortable: false,
            formatter: comercialNoteStatusBadgeForAdmin,
          },
          {
            label: 'Quitação',
            key: 'quitacao',
            sortable: false,
            formatter: liquidationStatusBadge,
          },
        ],
        actions: [
          {
            label: 'Visualizar',
            icon: 'icon-search',
            action: 'show',
            name: 'admin-concessions.show',
          },
        ],
        filters: [{
          category: 'Por parâmetro',
          fields: [
            {
              label: 'CNPJ',
              key: 'cnpj',
              type: 'text',
            },
            {
              label: 'Empresa',
              key: 'nome_fantasia',
              type: 'text',
            },
            {
              label: 'Data de resgate',
              key: 'data_emissao',
              type: 'date',
            },
            {
              label: 'Data de vencimento',
              key: 'data_vencimento',
              type: 'date',
            },
            {
              label: 'Status de recebimento',
              key: 'status',
              type: 'select',
              options: [
                {
                  label: 'Aguardando cadastro',
                  value: 'aguardando_cadastro',
                },
                {
                  label: 'Aguardando depósito',
                  value: 'aguardando_transferencia',
                },
                {
                  label: 'Depositado > Cliente',
                  value: 'executada',
                },
                {
                  label: 'Depositado > Roadcard',
                  value: 'depositada',
                },
                {
                  label: 'Liquidada',
                  value: 'liquidada',
                },
                {
                  label: 'Cancelada',
                  value: 'cancelada',
                },
                {
                  label: 'Vencida',
                  value: 'vencida',
                },
              ],
            },
            {
              label: 'Status de quitação',
              key: 'quitacao',
              type: 'select',
              options: [
                {
                  label: 'Aguardando pagamento',
                  value: 'Aguardando pagamento',
                },
                {
                  label: 'Vencida',
                  value: 'Vencida',
                },
                {
                  label: 'Paga',
                  value: 'Paga',
                },
              ],
            },
          ],
        }],
      },
    },
  },
  {
    path: '/admin/concessoes/:id',
    name: 'admin-concessions.show',
    component: () => import('@/views/admin/concessions/pages/Show.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
    },
  },
  {
    path: '/admin/concessoes/:id/liquidacao',
    name: 'admin-concessions.liquidation',
    component: () => import('@/views/admin/concessions/pages/Liquidation.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
    },
  },
  {
    path: '/admin/onboarding',
    name: 'admin-onboarding-followup.index',
    component: () => import('@/views/admin/onboarding/pages/Index.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
      pageTitle: 'Acompanhamento de onboarding',
      indexObject: {
        serviceUrl: 'backoffice/relatorios_de_empresa',
        datatableTitle: 'Gerencie abaixo os status das empresas na plataforma',
        removeTHead: true,
        filters: [{
          category: 'Por parâmetro',
          fields: [
            {
              label: 'Envio de convite',
              key: 'envio_convite',
              type: 'select',
              options: [
                {
                  label: 'Aguardando envio',
                  value: 'aguardando_envio',
                },
                {
                  label: 'Convite enviado',
                  value: 'convite_enviado',
                },
              ],
            },
            {
              label: 'Cadastro na plataforma',
              key: 'cadastro_na_plataforma',
              type: 'select',
              options: [
                {
                  label: 'Aguardando cadastro',
                  value: 'aguardando_cadastro',
                },
                {
                  label: 'Cadastro realizado',
                  value: 'cadastro_realizado',
                },
              ],
            },
            {
              label: 'Documentação e SCR',
              key: 'documentacao_scr',
              type: 'select',
              options: [
                {
                  label: 'Aguardando envio',
                  value: 'aguardando_envio',
                },
                {
                  label: 'Enviado pelo cliente',
                  value: 'enviado_cliente',
                },
                {
                  label: 'Não aplicável',
                  value: 'not_applied',
                },
              ],
            },
            {
              label: 'Análise de limite',
              key: 'limite',
              type: 'select',
              options: [
                {
                  label: 'Aprovado',
                  value: 'aprovado',
                },
                {
                  label: 'Reprovado',
                  value: 'reprovado',
                },
                {
                  label: 'Aguardando',
                  value: 'aguardando',
                },
                {
                  label: 'Não aplicável',
                  value: 'not_applied',
                },
              ],
            },
            {
              label: 'Envio de documentação',
              key: 'envio_de_documentacao',
              type: 'select',
              options: [
                {
                  label: 'Aguardando envio',
                  value: 'aguardando_envio',
                },
                {
                  label: 'Documentação enviada',
                  value: 'documentacao_enviada',
                },
                {
                  label: 'Não aplicável',
                  value: 'not_applied',
                },
              ],
            },
            {
              label: 'Análise de documentação',
              key: 'analise_de_documentacao',
              type: 'select',
              options: [
                {
                  label: 'Aguardando',
                  value: 'aguardando',
                },
                {
                  label: 'Aprovado',
                  value: 'aprovado',
                },
                {
                  label: 'Reprovado',
                  value: 'reprovado',
                },
                {
                  label: 'Não aplicável',
                  value: 'not_applied',
                },
              ],
            },
            {
              label: 'Apto para operar',
              key: 'apto_para_operar',
              type: 'select',
              options: [
                {
                  label: 'Sim',
                  value: 'sim',
                },
                {
                  label: 'Não',
                  value: 'nao',
                },
              ],
            },
          ],
        }],
      },
    },
  },
  {
    path: '/admin/usuarios',
    name: 'admin-users.index',
    component: () => import('@/views/admin/users/pages/Index.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
      pageTitle: 'Listar usuários',
      pageSubtitle: 'Listagem de usuários cadastrados no sistema',
      indexObject: {
        serviceUrl: '/backoffice/admin/users',
        editRouteName: 'admin-users.edit',
        fields: [
          {
            label: '',
            key: 'select',
          },
          {
            label: 'Nome',
            key: 'name',
            sortable: false
          },
          {
            label: 'Email',
            key: 'email',
            sortable: false
          },
          {
            label: 'Tipo',
            key: 'user_type',
            sortable: false,
            formatter: userTypeName,
          },
          {
            label: 'Empresa',
            key: 'empresa.nome_fantasia',
            sortable: false
          },
          {
            label: 'Status',
            key: 'status',
            sortable: false,
            formatter: userStatusBadge,
          },
        ],
        actions: [
          {
            label: 'Visualizar',
            icon: 'icon-search',
            action: 'edit',
            route: 'admin-users.edit',
          },
        ],
        filters: [
          {
            category: 'Por parâmetro',
            fields: [
              {
                label: 'Nome',
                key: 'name',
                type: 'text'
              },
              {
                label: 'E-mail',
                key: 'email',
                type: 'text'
              },
              {
                label: 'Tipo',
                key: 'user_type',
                type: 'select',
                options: [
                  { label: 'Administrador', value: 'admin' },
                  { label: 'Api', value: 'api' },
                  { label: 'Empresa', value: 'empresa' },
                  { label: 'Parceiro', value: 'parceiro' }
                ],
              },
              {
                label: 'Status',
                key: 'status',
                type: 'select',
                options: [
                  { label: 'Ativo', value: 'active' },
                  { label: 'Inativo', value: 'inactive' },
                  { label: 'Bloqueado', value: 'blocked' }
                ],
              },
            ],
          },
        ],
      },
    },
  },
  {
    path: '/admin/usuarios/create',
    name: 'admin-users.create',
    component: () => import('@/views/admin/users/pages/Create.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
      pageTitle: 'Criar usuário',
      pageSubtitle: 'Cadastrar novo usuário admin no sistema'
    },
  },
  {
    path: '/admin/usuarios/edit/:id',
    name: 'admin-users.edit',
    component: () => import('@/views/admin/users/pages/Edit.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
      pageTitle: 'Editar usuário',
      pageSubtitle: 'Editar usuário no sistema'
    },
  },
]
