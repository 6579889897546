import http from "@/services/http";
import Invite from "@/services/invite";
import Auth from "@/services/auth";
import Crud from "@/services/crud";

const getBanks = ({ commit, rootState }) => {
    if (rootState.common.bank.list.length) return

    return new Promise((resolve, reject) => {
        http.get('/bancos').then(response => {
            commit('SET_BANK_LIST', response.data)
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}

const validateInvite = ({}, token) => {
    return new Promise((resolve, reject) => {
        Invite.validate(token).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}

const validateResetToken = ({}, token) => {
    return new Promise((resolve, reject) => {
        Auth.validateResetToken(token).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}

const exportToXlsx = ({}, payload) => {
    return new Promise((resolve, reject) => {
        Crud.exportToXlsx(payload).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}


export default {
    getBanks,
    validateInvite,
    validateResetToken,
    exportToXlsx
}
