import Concession from "@/services/concession";

const fetch = ({ commit }, id) => {
    return new Promise((resolve, reject) => {
        Concession.getConcession(id)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
    })
}

const partnerFetch = ({ commit }, id) => {
    return new Promise((resolve, reject) => {
        Concession.getPartnerConcession(id)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
    })
}

const getAll = ({ }) => {
    return new Promise((resolve, reject) => {
        Concession.fetch()
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
    })
}

const adminFetch = ({ commit }, id) => {
    return new Promise((resolve, reject) => {
        Concession.getConcessionByAdmin(id)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
    })

}

const checkForCanceledNotes = ({ commit }, id) => {
    return new Promise((resolve, reject) => {
        Concession.checkForCanceledNotes()
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
    })

}

const checkForExpiredNotes = ({ commit }) => {
  return new Promise((resolve, reject) => {
      Concession.checkForExpiredNotes()
          .then(response => {
              resolve(response)
          })
          .catch(error => {
              reject(error)
          })
  })

}

const checkForExecutedNotes = ({ commit }) => {
  return new Promise((resolve, reject) => {
      Concession.checkForExecutedNotes()
          .then(response => {
              resolve(response)
          })
          .catch(error => {
              reject(error)
          })
  })

}

const checkForToExpiringNotes = ({ commit }) => {
    return new Promise((resolve, reject) => {
        Concession.checkForToExpiringNotes()
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
    })
  
  }

const generateCnabFileForConcessions = ({ }, ids) => {
    return new Promise((resolve, reject) => {
        Concession.generateCnabFile(ids)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
    })
}

const makeDeposit = ({ }, notaComercialID) => {
    return new Promise((resolve, reject) => {
        Concession.deposit(notaComercialID)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
    })
}

const makeDepositClient = ({ }, notaComercialID) => {
    return new Promise((resolve, reject) => {
        Concession.depositClient(notaComercialID)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
    })
}

const changeStatus = ({ }, payload) => {
    return new Promise((resolve, reject) => {
        Concession.updateStatus(payload.notaComercialID, payload.status)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
    })
}

const liquidate = ({ }, payload) => {
    return new Promise((resolve, reject) => {
        Concession.createLiquidation(payload)
            .then( async response => {
                await Concession.executeLiquidation(response.data.id)
                .then(response => {
                    resolve(response)
                })
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
    })
}

const liquidateLate = ({ }, payload) => {
    return new Promise((resolve, reject) => {
        console.log(payload)
        Concession.createDelayedLiquidation(payload.id, payload.data_liquidacao)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
    })
}

const getPreviewEncargos = ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
        Concession.getPreviewEncargos(payload.id, payload.data_pagamento)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
    })

}
const postNcSetDue = ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
        Concession.postNcSetDue(payload.id)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
    })

}


export default {
    fetch,
    getAll,
    adminFetch,
    checkForCanceledNotes,
    checkForExpiredNotes,
    checkForExecutedNotes,
    checkForToExpiringNotes,
    generateCnabFileForConcessions,
    makeDeposit,
    makeDepositClient,
    makeDepositClient,
    partnerFetch,
    liquidate,
    liquidateLate,
    getPreviewEncargos,
    postNcSetDue,
}
