import PartnerDashboard from '@/services/partner_dashboard';

export default {
  getStatsData({ commit }) {
    return new Promise((resolve, reject) => {
      PartnerDashboard.statsData()
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}
