export default {
  // Endpoints
  loginEndpoint: '/auth/login', // replace default login endpoint
  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
  storageUserDataKeyName: 'userData', // userData key name in storage

  // Send API requests with credentials (cookies, authorization headers, etc.).
  useRefresh: true,
  refreshTokenTTL: 60 * 60 * 24 * 30, // 30 days
  jwtAuthScheme: 'JWT', // default
}
