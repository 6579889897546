import {
  dateFormatter,
  moneyFormatDatatable,
  percentageFormatter,
  prazoFormatter,
  comercialNoteStatusBadge,
  liquidationStatusBadge,
  badgeClassification,
  documentationBadge,
} from "@core/comp-functions/data_visualization/datatable";

export default [
  {
    path: '/parceiro/graficos',
    name: 'partner-graphs.index',
    component: () => import('@/views/partner/dashboard/pages/Index.vue'),
    meta: {
      pageTitle: "Dados de quitação e inadimplência",
      pageSubtitle: "Acompanhe abaixo os dados disponíveis",
      action: 'partner-read',
      resource: 'partner-user',
      actions: [{
        title: 'Entre em contato',
        variant: 'outline-primary',
        event: 'redirect-wapp'
      }],
      indexObject: {
        serviceUrl: '/concessao/parceiro/list',
        fields: [
          {
            label: 'CNPJ',
            key: 'empresa.cnpj',
            sortable: true,
          },
          {
            label: 'Empresa',
            key: 'empresa.nome_fantasia',
            sortable: true,
          },
          {
            label: 'Valor contratado',
            key: 'valor_emissao',
            sortable: true,
            formatter: moneyFormatDatatable,
          },
          {
            label: 'Valor a pagar',
            key: 'valor_devido',
            sortable: true,
            formatter: moneyFormatDatatable,
          },
          {
            label: 'Taxa a.m.',
            key: 'taxa_juros',
            sortable: true,
            formatter: percentageFormatter
          },
          {
            label: 'Taxa de Escrituração',
            key: 'taxa_escrituracao',
            sortable: true,
            formatter: percentageFormatter,
          },
          {
            label: 'Resgate',
            key: 'data_emissao',
            sortable: true,
            formatter: dateFormatter
          },
          {
            label: 'Vencimento',
            key: 'data_vencimento',
            sortable: true,
            formatter: dateFormatter
          },
          {
            label: 'Prazo',
            key: 'prazo',
            sortable: false,
            formatter: prazoFormatter
          },
          {
            label: 'Nota Comercial',
            key: 'status',
            sortable: false,
            formatter: comercialNoteStatusBadge
          },
          {
            label: 'Quitação',
            key: 'quitacao',
            sortable: false,
            formatter: liquidationStatusBadge
          },
        ],
        actions: [
          {
            label: 'Visualizar',
            icon: 'icon-search',
            action: 'show',
            name: 'concessoes.show'
          },
        ],
        editRouteName: 'partner-concessoes.show',
        filters: [{
          category: 'Por parâmetro',
          fields: [{
            label: 'Status',
            key: 'status',
            type: 'select',
            options: [
              {
                label: 'Aguardando assinatura',
                value: 'aguardando_assinatura',
              },
              {
                label: 'Aguardando depósito',
                value: 'aguardando_transferencia',
              },
              {
                label: 'Depositado > Client',
                value: 'depositada',
              },
              {
                label: 'Depositado > Roadcard',
                value: 'executada',
              },
              {
                label: 'Liquidada',
                value: 'liquidada',
              },
              {
                label: 'Cancelada',
                value: 'cancelada',
              },
              {
                label: 'Vencida',
                value: 'vencida',
              },
            ]
          },
          {
            label: 'Quitação',
            key: 'quitacao',
            type: 'select',
            options: [
              {
                label: 'Paga',
                value: 'Paga',
              },
              {
                label: 'Em atraso',
                value: 'Em atraso',
              },
              {
                label: 'A vencer',
                value: 'A vencer',
              },
            ]
          }
        ]},{
          category: 'Por período',
          fields: [{
            label: 'Resgate',
            key: 'resgate',
            type: 'date-range',
          }, {
            label: 'Vencimento',
            key: 'vencimento',
            type: 'date-range',
          }]
        }]
      },
    }
  },
  {
    path: '/parceiro/concessoes/:id',
    name: 'partner-concessoes.show',
    component: () => import('@/views/partner/concessoes/pages/Show.vue'),
    meta: {
      action: 'partner-read',
      resource: 'partner-user',
      pageTitle: 'Dashboard',
    }
  },
  {
    path: '/parceiro/dashbord',
    name: 'partner-companies.show',
    component: () => import('@/views/partner/companies/show.vue'),
    meta: {
      action: 'partner-read',
      resource: 'partner-user',
      indexObject: {
        serviceUrl: 'backoffice/empresas',
        editRouteName: 'partner-dashboard.general-data',
        datatableTitle: 'Visualize as empresas da plataforma',
        fields: [
          {
            label: 'CNPJ',
            key: 'cnpj',
            sortable: true,
          },
          {
            label: 'Nome fantasia',
            key: 'nome_fantasia',
            sortable: true,
          },
          {
            label: 'Razão social',
            key: 'razao_social',
            sortable: true,
          },
          {
            label: 'Limite aprovado',
            key: 'limite_aprovado',
            sortable: true,
            formatter: 'booleanFormatter',
          },
          {
            label: 'Documentação',
            key: 'status_documentacao',
            sortable: true,
            formatter: documentationBadge,
          },
          {
            label: 'Apto para operar',
            key: 'apto_para_operar',
            sortable: true,
            formatter: 'booleanFormatter',
          },
          {
            label: 'Classificação',
            key: 'classificacao_interna',
            sortable: true,
            formatter: badgeClassification,
          },
        ],
        actions: [
          {
            label: 'Editar',
            icon: 'icon-pencil',
            action: 'edit',
          },
          {
            label: 'Visualizar',
            icon: 'icon-pencil',
            action: 'show',
          },
          {
            label: 'Excluir',
            icon: 'icon-trash',
            action: 'delete',
          },
        ],
        filters: [{
          category: 'Por parâmetro',
          fields: [
            {
              label: 'CNPJ',
              key: 'cnpj',
              type: 'text',
            },
            {
              label: 'Nome Fantasia',
              key: 'nome_fantasia',
              type: 'text',
            },
            {
              label: 'Razão Social',
              key: 'razao_social',
              type: 'text',
            },
            {
              label: 'Limite Aprovado',
              key: 'limite_aprovado',
              type: 'select',
              options: [
                {
                  label: 'Sim',
                  value: 'true',
                },
                {
                  label: 'Não',
                  value: 'false',
                },
              ],
            },
            {
              label: 'Documentação',
              key: 'status_documentacao',
              type: 'select',
              options: [
                {
                  label: 'Não enviada',
                  value: 'nao_enviado',
                },
                {
                  label: 'Em análise',
                  value: 'em_validacao',
                },
                {
                  label: 'Aprovado',
                  value: 'aprovado',
                },
                {
                  label: 'Reprovado',
                  value: 'reprovado',
                },
              ],
            },
            {
              label: 'Classificação',
              key: 'classificacao_interna',
              type: 'select',
              options: [
                {
                  label: 'AAA',
                  value: 'aaa',
                },
                {
                  label: 'M',
                  value: 'm',
                },
              ],
            },
            {
              label: 'Apto Para Operar',
              key: 'apto_para_operar',
              type: 'select',
              options: [
                {
                  label: 'Sim',
                  value: 'true',
                },
                {
                  label: 'Não',
                  value: 'false',
                },
              ],
            },
          ],
        }],
      },
    },
  },
  {
    path: '/parceiro/companies/:id',
    name: 'partner-companies.edit',
    component: () => import('@/views/partner/companies/showById.vue'),
    meta: {
      action: 'partner-read',
      resource: 'partner-user',
      pageTitle: 'Empresa',
    },
    children: [
      {
        path: 'dados-gerais',
        name: 'partner-dashboard.general-data',
        component: () => import('@/views/partner/companies/pages/GeneralData.vue'),
        meta: {
          action: 'partner-read',
          resource: 'partner-user',
          title: 'Empresa',
        },
      },
      // {
      //   path: 'representantes',
      //   name: 'partner-dashboard.representatives',
      //   component: () => import('@/views/partner/companies/pages/Representative.vue'),
      //   meta: {
      //     action: 'partner-read',
      //     resource: 'partner-user',
      //     title: 'Representantes legais',
      //   },
      // },
      {
        path: 'documentos',
        name: 'partner-dashboard.documents',
        component: () => import('@/views/partner/companies/pages/Documents.vue'),
        meta: {
          action: 'partner-read',
          resource: 'partner-user',
          title: 'Análise de documentos',
        },
      },
      // {
      //   path: 'taxa',
      //   name: 'partner-dashboard.tax',
      //   component: () => import('@/views/partner/companies/pages/Tax.vue'),
      //   meta: {
      //     action: 'partner-read',
      //     resource: 'partner-user',
      //     title: 'Taxa, prazo e limites',
      //   },
      // },
      // {
      //   path: 'convites',
      //   name: 'partner-dashboard.invite',
      //   component: () => import('@/views/partner/companies/pages/Invite.vue'),
      //   meta: {
      //     action: 'partner-read',
      //     resource: 'partner-user',
      //     title: 'Convites',
      //   },
      // },
    ],
  },
  {
    path: '/partner/onboarding',
    name: 'partner-onboarding-followup.index',
    component: () => import('@/views/partner/onboarding/Index.vue'),
    meta: {
      action: 'partner-read',
      resource: 'partner-user',
      pageTitle: 'Acompanhamento de onboarding',
      indexObject: {
        serviceUrl: 'backoffice/relatorios_de_empresa',
        datatableTitle: 'Gerencie abaixo os status das empresas na plataforma',
        removeTHead: true,
        filters: [{
          category: 'Por parâmetro',
          fields: [
            {
              label: 'Envio de convite',
              key: 'envio_convite',
              type: 'select',
              options: [
                {
                  label: 'Aguardando envio',
                  value: 'aguardando_envio',
                },
                {
                  label: 'Convite enviado',
                  value: 'convite_enviado',
                },
              ],
            },
            {
              label: 'Cadastro na plataforma',
              key: 'cadastro_na_plataforma',
              type: 'select',
              options: [
                {
                  label: 'Aguardando cadastro',
                  value: 'aguardando_cadastro',
                },
                {
                  label: 'Cadastro realizado',
                  value: 'cadastro_realizado',
                },
              ],
            },
            {
              label: 'Documentação e SCR',
              key: 'documentacao_scr',
              type: 'select',
              options: [
                {
                  label: 'Aguardando envio',
                  value: 'aguardando_envio',
                },
                {
                  label: 'Enviado pelo cliente',
                  value: 'enviado_cliente',
                },
                {
                  label: 'Não aplicável',
                  value: 'not_applied',
                },
              ],
            },
            {
              label: 'Análise de limite',
              key: 'limite',
              type: 'select',
              options: [
                {
                  label: 'Aprovado',
                  value: 'aprovado',
                },
                {
                  label: 'Reprovado',
                  value: 'reprovado',
                },
                {
                  label: 'Aguardando',
                  value: 'aguardando',
                },
                {
                  label: 'Não aplicável',
                  value: 'not_applied',
                },
              ],
            },
            {
              label: 'Envio de documentação',
              key: 'envio_de_documentacao',
              type: 'select',
              options: [
                {
                  label: 'Aguardando envio',
                  value: 'aguardando_envio',
                },
                {
                  label: 'Documentação enviada',
                  value: 'documentacao_enviada',
                },
                {
                  label: 'Não aplicável',
                  value: 'not_applied',
                },
              ],
            },
            {
              label: 'Análise de documentação',
              key: 'analise_de_documentacao',
              type: 'select',
              options: [
                {
                  label: 'Aguardando',
                  value: 'aguardando',
                },
                {
                  label: 'Aprovado',
                  value: 'aprovado',
                },
                {
                  label: 'Reprovado',
                  value: 'reprovado',
                },
                {
                  label: 'Não aplicável',
                  value: 'not_applied',
                },
              ],
            },
            {
              label: 'Apto para operar',
              key: 'apto_para_operar',
              type: 'select',
              options: [
                {
                  label: 'Sim',
                  value: 'sim',
                },
                {
                  label: 'Não',
                  value: 'nao',
                },
              ],
            },
          ],
        }],
      },
    },
  },
]
