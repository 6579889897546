import mutations from './mutations'
import actions from './actions'

export default {
  namespaced: true,
  mutations,
  state: {
    current: {
      representantes_legais: [],
      contas_correntes: [],
      status_analise: 'nao_solicitado',
      status_scr_consent: 'nao_concluido',
    },
  },
  actions,
}
