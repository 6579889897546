import http from './http';

class XmlNfe {
  createNfe(payload) {
    let formData = new FormData();

    formData.append('file', payload.file);
    formData.append('tipo', payload.tipo);

    return http.post('/analise_credito/nfes', formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });
  }

  get() {
    return http.get('/analise_credito/nfes');
  }
}

export default new XmlNfe();