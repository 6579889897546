import jwt from '@/auth/jwt/useJwt'
import Auth from '@/services/auth'

export default {
  login({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt.login({ auth_user: payload })
        .then(async response => {
          const { user_data } = response.data
          const role = {
            admin: 'admin',
            empresa: 'company',
            parceiro: 'partner',
          }
          const defaultAction = `${role[user_data.user_type]}-read`

          jwt.setToken(response.data.auth_token)

          if (!user_data.permissions.length) {
            user_data.ability = [{
              resource: 'All',
              action: defaultAction,
            }]
          } else {
            user_data.ability = user_data.permissions.map(permission => ({
              action: defaultAction,
              resource: permission.resource,
            }))
          }

          user_data.ability.push({ action: 'read', resource: 'Auth' })
          jwt.setUserData(user_data)
          commit('auth/SET_USER_DATA', user_data, { root: true })
          // TODO levar em consideracao a request 'current' ao inves dessa e status_analise tmb
          if (user_data.empresa?.status_documentacao === 'reprovado') commit('appConfig/ADD_ALERT_BADGE', null, { root: true })
          if (user_data.user_type === 'empresa') await dispatch('getUserData')

          //! isso esta sendo feito aqui pois a informacao de que o usuario ja enviou o scr nao esta sendo salva no banco
          if (user_data.user_type === 'empresa' && user_data.empresa.status_analise === 'finalizada') {
            commit('auth/SET_SCR_CONSENT_STATUS', null, { root: true })
            commit('company/SET_SCR_CONSENT_STATUS', null, { root: true })
          }
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  createUser(context, payload) {
    return new Promise((resolve, reject) => {
      Auth.createUser(payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  sendResetPasswordEmail(context, payload) {
    return new Promise((resolve, reject) => {
      Auth.sendResetPasswordEmail(payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  getUserData({ commit }) {
    return new Promise((resolve, reject) => {
      Auth.userData()
        .then(response => {
          commit('auth/UPDATE_USER_DATA', response.data, { root: true })
          commit('auth/SET_NEW_LIMIT', response.data.limite_disponivel, { root: true })
          commit('company/SET_CURRENT', response.data, { root: true })
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // use :context instead of empty object or unused vars
  newPassword(context, payload) {
    return new Promise((resolve, reject) => {
      Auth.newPassword(payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  async logout() {
    return jwt.logout()
  },
}
