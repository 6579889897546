import Company from '@/services/company'
import CreditAnalysis from '@/services/credit_analysis'
import Invite from '@/services/invite'
import Representative from '@/services/representative'
import BankAccount from '@/services/bankAccount'

const sendDocumentToAnalysis = (context, payload) =>
  new Promise((resolve, reject) => {
    CreditAnalysis.sendDocument(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })
const deleteDocumentToAnalysis = (context, payload) =>
  new Promise((resolve, reject) => {
    CreditAnalysis.deleteDocument(payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error))
  })

const complementCompanyData = (context, payload) =>
  new Promise((resolve, reject) => {
    Company.complementInfos(payload)
      .then((response) => {
        // commit('UPDATE_CURRENT', payload)
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })

const fetch = ({ commit }, id) =>
  new Promise((resolve, reject) => {
    Company.getCompany(id)
      .then((response) => {
        commit('SET_CURRENT', response.data)
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })

const enviarDocumentacaoParaAnalise = (context, id) =>
  new Promise((resolve, reject) => {
    Company.enviarDocumentacaoParaAnalise(id)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })

const createInviteFromCompany = (context, params) =>
  new Promise((resolve, reject) => {
    const { id, ...payload } = params
    Company.createInviteContact(id, payload)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })

const createRepresentative = ({ rootState }, payload) => {
  const { user_type } = rootState.auth.userData
  const representative = new Representative(user_type)
  return new Promise((resolve, reject) => {
    representative
      .create(payload)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const saveRepresentative = ({ dispatch, rootState }, payload) => {
  const { user_type } = rootState.auth.userData
  return new Promise((resolve, reject) => {
    const representative = new Representative(user_type)
    const method = payload.id ? 'update' : 'save'
    representative[method](payload)
      .then(async (response) => {
        await dispatch(
          'fetch',
          payload.empresa_id ? payload.empresa_id : rootState.auth.userData.empresa_id,
        )
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const destroyRepresentative = ({ dispatch, rootState }, id) => {
  const { user_type } = rootState.auth.userData
  const representative = new Representative(user_type)
  return new Promise((resolve, reject) => {
    representative
      .destroy(id)
      .then(async (response) => {
        await dispatch('fetch', rootState.company.current.id)
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const saveCompanyBankAccount = ({ dispatch, rootState }, payload) =>
  new Promise((resolve, reject) => {
    const method = payload.id ? 'update' : 'save'
    BankAccount[method](payload)
      .then(async (response) => {
        await dispatch('fetch', rootState.auth.userData.empresa_id)
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })

const destroyBankAccount = ({ dispatch, rootState }, id) =>
  new Promise((resolve, reject) => {
    BankAccount.destroy(id)
      .then(async (response) => {
        await dispatch('fetch', rootState.auth.userData.empresa_id)
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })

const uploadSocialContract = ({ commit, rootState }, payload) => {
  const id = rootState.auth.userData.empresa_id
  return new Promise((resolve, reject) => {
    Company.uploadSocialContract(id, payload)
      .then((response) => {
        commit('auth/SET_STATUS_DOCUMENTATION', null, { root: true })
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const approve = (context, payload) =>
  new Promise((resolve, reject) => {
    Company.approveDocuments(payload)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })

const repprove = (context, payload) =>
  new Promise((resolve, reject) => {
    Company.repproveDocuments(payload)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })

const updateTax = (context, id) =>
  new Promise((resolve, reject) => {
    Company.updateTax(id)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })

const getInvites = (context, id) =>
  new Promise((resolve, reject) => {
    Company.getInvites(id)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })

const createInvite = (context, { id, payload }) =>
  new Promise((resolve, reject) => {
    Invite.create(id, payload)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })

const resendInvite = (context, id) =>
  new Promise((resolve, reject) => {
    Invite.resend(id)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })

const deleteInvite = (context, payload) => {
  const { companyId, inviteId } = payload
  return new Promise((resolve, reject) => {
    Invite.delete(companyId, inviteId)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const getAnalysisResult = (context, id) =>
  new Promise((resolve, reject) => {
    Company.analysisResult(id)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })

const checkGuarantorRepresentative = ({ rootState }, id) => {
  const { user_type } = rootState.auth.userData
  const representative = new Representative(user_type)
  return new Promise((resolve, reject) => {
    representative
      .hasGuarantor(id)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const checkSubscribers = ({ rootState }, id) => {
  const { user_type } = rootState.auth.userData
  const representative = new Representative(user_type)
  return new Promise((resolve, reject) => {
    representative
      .hasValidSubscribers(id)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const toggleCanOperate = (context, payload) => {
  const { id, apto_para_operar } = payload
  return new Promise((resolve, reject) => {
    Company.updateCanOperate(id, { apto_para_operar })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export default {
  fetch,
  complementCompanyData,
  createInviteFromCompany,
  createRepresentative,
  saveRepresentative,
  destroyRepresentative,
  saveCompanyBankAccount,
  uploadSocialContract,
  destroyBankAccount,
  approve,
  repprove,
  updateTax,
  getInvites,
  createInvite,
  resendInvite,
  deleteInvite,
  getAnalysisResult,
  checkGuarantorRepresentative,
  enviarDocumentacaoParaAnalise,
  checkSubscribers,
  toggleCanOperate,
  sendDocumentToAnalysis,
  deleteDocumentToAnalysis,
}
