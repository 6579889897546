import http from './http';

class Invite {
  create(id, payload) {
    return http.post(`backoffice/empresas/${id}/create_convite_from_empresa`, { convite: payload })
  }

  resend(id) {
    return http.get(`backoffice/admin/convites/${id}/enviar_convite`)
  }

  delete(companyId, inviteId) {
    return http.delete(`backoffice/empresas/${companyId}/delete_convite_from_empresa/${inviteId}`)
  }

  validate(token) {
    return http.get(`validar_convite?token=${token}`)
  }
}

export default new Invite();