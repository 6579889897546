import http from './http';

class Reports {
  downloadOnboardingXlsx(payload) {
    const params = new URLSearchParams(payload).toString()

    return http.get(`/backoffice/relatorios_de_empresa_xlsx?${params}`, { responseType: 'blob' });
  }
}

export default new Reports();
