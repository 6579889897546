import actions from './actions'
import mutations from './mutations'

export default {
  namespaced: true,
  state: {
    userData: {
      empresa: {
        status_analise: 'nao_solicitado',
        status_scr_consent: 'nao_concluido',
      },
      notifications: [],
    },
  },
  mutations,
  actions,
}
