const SET_CURRENT = (state, obj) => {
  Object.assign(state.current, obj)
}

const UPDATE_CURRENT = (state, obj) => {
  Object.assign(state.current, obj)
}

const SET_SCR_CONSENT_STATUS = state => {
  state.current.status_scr_consent = 'concluido'
}

export default {
  SET_CURRENT,
  UPDATE_CURRENT,
  SET_SCR_CONSENT_STATUS,
}
