import Reports from "@/services/reports";
import AdminUser from "@/services/adminUser";

const downloadOnboardingXlsx = ({}, payload) => {
    return new Promise((resolve, reject) => {
        Reports.downloadOnboardingXlsx(payload)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
}

const fetchAllUsers = ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
        AdminUser.fetchAll(payload)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
}
const fetchUser = ({ commit }, id) => {
    return new Promise((resolve, reject) => {
        AdminUser.fetch(id)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
}
const createUser = ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
        AdminUser.create(payload)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
}
const updateUser = ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
        AdminUser.update(payload.id, payload)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
}
const blockUsers = ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
        AdminUser.blockUsers(payload)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
}
const unblockUsers = ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
        AdminUser.unblockUsers(payload)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
}

export default {
    downloadOnboardingXlsx,
    fetchUser,
    createUser,
    fetchAllUsers,
    blockUsers,
    unblockUsers,
    updateUser
}
